// @flow
import React, { useEffect, useRef, useState, forwardRef } from 'react';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import Tippy from '@tippyjs/react/headless';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import { sticky } from 'tippy.js/headless';
import { useSpring, motion } from 'framer-motion';
import { Colors, Spacings } from 'styles';
import { NOOP } from 'config/constants';
import { maxSize, applyMaxSize } from 'utils/popperModifiers';
import { transparentize } from 'color2k';
import { useKey } from 'react-use';
import { env } from 'config/env';
import Text from '../Text';
import { css } from 'styled-components';

const springConfig = {
  damping: 80,
  stiffness: 800,
};

const POPPER_OPTIONS = {
  modifiers: [
    { ...maxSize, options: { padding: { bottom: 15 } } },
    {
      ...applyMaxSize,
      options: { tippy: true },
    },
  ],
};

// Disable animations during storyshots to improve stability
const INITIAL_SCALE = env.STORYBOOK_STORYSHOTS === 'true' ? 1 : 0.85;
const HIDE_SCALE = env.STORYBOOK_STORYSHOTS === 'true' ? 1 : 0.95;

const MotionDiv = motion.ul;

type TippyInstance = $FlowFixMe;

type Props = {
  render: (instance: TippyInstance) => React$Node,

  children?: React$Node,
  reference?: Element,
  appendTo?: Element | (() => ?Element),
  offset?: [?number, ?number],
  placement?: string,
  visible?: boolean,
  trigger?: string,
  hideOnClick?: boolean | 'toggle',
  onClickOutside?: (instance: TippyInstance) => void,
  onMount?: (instance: TippyInstance) => void,
  onShow?: (instance: TippyInstance) => void,
  onHide?: (instance: TippyInstance) => void,
  onHidden?: (instance: TippyInstance) => void,
  width?: string | number,
  css?: mixed,
  className?: string,
  'data-testid'?: string,
};

type Instance = {
  unmount: () => void,
};

function MenuComponent(
  {
    visible,
    render,
    children,
    reference,
    offset,
    placement = 'bottom',
    onMount,
    onHidden,
    onHide,
    onClickOutside,
    width,
    className,
    'data-testid': dataTestid,
    ...props
  }: Props,

  ref: React$Ref<typeof Tippy>
) {
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(INITIAL_SCALE, springConfig);
  const scrollTopRef = useRef();
  const [menu, setMenu] = useState(null);

  function internalOnMount(instance: Instance) {
    onMount && onMount(instance);

    scale.set(1);
    opacity.set(1);
  }

  function internalOnHide(instance: Instance) {
    onHide && onHide(instance);

    const cleanup = scale.onChange((value) => {
      if (value <= HIDE_SCALE) {
        cleanup();
        instance.unmount();
      }
    });

    scale.set(HIDE_SCALE);
    opacity.set(0);
  }

  function internalOnHidden(instance: Instance) {
    onHidden && onHidden(instance);

    scale.set(INITIAL_SCALE);
  }

  useEffect(() => {
    if (menu) {
      menu.scrollTop = scrollTopRef.current;
    }
  }, [reference, menu]);

  const menuWidth =
    width != null ? `${String(width)}${typeof width === 'number' ? 'px' : ''}` : 'auto';

  useKey('Escape', onClickOutside);

  return (
    <Tippy
      {...props}
      ref={ref}
      visible={visible}
      placement={placement}
      offset={offset ?? [0, 0]}
      onMount={internalOnMount}
      onHide={internalOnHide}
      onHidden={internalOnHidden}
      onClickOutside={onClickOutside || NOOP}
      // Disable the animation on Storyshots to improve stability
      animation={env.STORYBOOK_STORYSHOTS === 'false'}
      interactive
      // NOTE(perf): we need this plugin because of the scale animation on the
      // parent Tippy. It does have a perf cost, so if possible, it should only
      // apply while the animation is occurring.
      plugins={[sticky]}
      sticky
      popperOptions={POPPER_OPTIONS}
      reference={reference}
      render={(attrs, content, instance) => (
        <MotionDiv
          ref={setMenu}
          {...attrs}
          style={{ opacity, scale }}
          onScroll={() => {
            // $FlowFixMe[incompatible-use]
            scrollTopRef.current = menu.scrollTop ?? 0;
          }}
          className={className}
          data-testid={dataTestid}
          css={`
            background-color: ${Colors.gray3};
            color: ${Colors.gray10};
            border-radius: 0.5rem;
            overflow-y: auto;
            box-shadow: 0 0.5rem 1rem ${transparentize(Colors.gray1, 0.5)};
            width: ${menuWidth};
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
            padding-top: ${Spacings.xsmall}em;
            padding-bottom: ${Spacings.xsmall}em;

            &[data-placement='top'] {
              transform-origin: bottom;
            }
            &[data-placement='top-start'] {
              transform-origin: left bottom;
            }
            &[data-placement='top-end'] {
              transform-origin: right bottom;
            }

            &[data-placement='bottom'] {
              transform-origin: top;
            }
            &[data-placement='bottom-start'] {
              transform-origin: left top;
            }
            &[data-placement='bottom-end'] {
              transform-origin: right top;
            }

            &[data-placement='left'] {
              transform-origin: right;
            }
            &[data-placement='left-start'] {
              transform-origin: right top;
            }
            &[data-placement='left-end'] {
              transform-origin: right bottom;
            }

            &[data-placement='right'] {
              transform-origin: left;
            }
            &[data-placement='right-start'] {
              transform-origin: left top;
            }
            &[data-placement='right-end'] {
              transform-origin: left bottom;
            }
          `}
        >
          {render?.(instance) ?? null}
        </MotionDiv>
      )}
    >
      {children}
    </Tippy>
  );
}

export const Menu: React$AbstractComponent<Props, void> = forwardRef(MenuComponent);

export const MenuItem: React$ComponentType<{
  children: React$Node,
  disabled?: boolean,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => mixed,
  className?: string,
  css?: mixed,
  ...
}> = forwardRef(({ children, disabled, className, ...props }, ref) => (
  <li
    {...props}
    disabled={disabled}
    ref={ref}
    role="button"
    tabIndex={disabled === true ? -1 : 0}
    css={css`
      padding: 0;
      background-color: transparent;
      cursor: pointer;
      text-align: left;
      list-style: none;
      &:disabled {
        cursor: default;
      }
    `}
  >
    <Text
      variant="body1"
      css={css`
        padding: ${Spacings.xsmall}em ${Spacings.small}em;
        display: flex;
        align-items: center;
        color: ${Colors.gray10};
        &:hover,
        &:focus {
          background-color: ${disabled === true ? 'transparent' : Colors.gray4};
        }
        transition:
          background-color 0.2s ease-in-out,
          color 0.2s ease-in-out;
      `}
      className={className}
    >
      {children}
    </Text>
  </li>
));
MenuItem.displayName = 'MenuItem';

export const MenuHeader: React$ComponentType<{ children: React$Node }> = forwardRef(
  ({ children, ...props }, ref) => (
    <MenuItem disabled>
      <Text variant="body2" color="secondary" ref={ref}>
        {children}
      </Text>
    </MenuItem>
  )
);
MenuHeader.displayName = 'MenuHeader';
