// @flow
import { useState } from 'react';
import Text from 'common/ui/Text';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { Stack } from 'common/ui/Layout';
import { Colors } from 'styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import { ReactComponent as ReporterSettingsIcon } from 'assets/reporterIconNav.svg';
import { ReactComponent as ViewerSettingsIcon } from 'assets/ViewerSettingsIcon.svg';
import WorklistSettingsIcon from '@material-ui/icons/List';
import { ReactComponent as PersonalInformationIcon } from 'assets/PersonalInformationIcon.svg';
import SecuritySettingsIcon from '@material-ui/icons/Security';
import { transparentize } from 'color2k';
import { ViewerSettingsTab } from './ViewerSettingsTab';
import { PersonalInformationTab } from './PersonalInformationTab';
import { SecuritySettingsTab } from './SecuritySettingsTab';
import { ReporterSettingsTab } from './ReporterSettingsTab';
import { WorklistSettingsTab } from './WorklistSettingsTab';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledTab = withStyles((theme) => ({
  root: {
    width: '228px',
    minHeight: 'auto',
    padding: '8px 4px 8px 16px',
    fontWeight: 400,
    height: '48px',
    '& .MuiTab-wrapper > *:first-child': {
      marginBottom: 0,
    },
    textTransform: 'none',
    color: Colors.gray10,
    '&$selected': {
      background: transparentize(Colors.blue2, 0.92),
    },
  },
  wrapper: {
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    '& > *:first-child': {
      marginRight: '32px',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles((theme) => ({
  indicator: { backgroundColor: 'transparent' },
}))((props) => <Tabs {...props} />);

const StyledTabPanel = withStyles((theme) => ({
  root: {
    padding: 0,
    height: '100%',
    width: '100%',
  },
}))((props) => <TabPanel {...props} />);
type AccountProps = {
  onClose: () => void,
};
function Account({ onClose }: AccountProps): React$Node {
  const [error, setError] = useState(null);
  const [value, setValue] = useState('personal-information');

  return (
    <TabContext value={value}>
      <Stack style={{ height: '760px', width: '948px' }}>
        <div style={{ width: '228px' }}>
          <Text
            variant="display2"
            style={{
              margin: '1rem 16px',
              display: 'inline-block',
              minHeight: '48px',
              lineHeight: '48px',
            }}
          >
            Settings
          </Text>
          <StyledTabs value={value} onChange={(ev, val) => setValue(val)} orientation="vertical">
            <StyledTab
              icon={<PersonalInformationIcon />}
              label="Personal Information"
              value="personal-information"
              data-testid="personal-information"
            />
            <StyledTab
              icon={<ViewerSettingsIcon />}
              label="Viewer Settings"
              value="viewer-settings"
              data-testid="viewer-settings"
            />
            (
            <StyledTab
              icon={<SvgIcon component={ReporterSettingsIcon} />}
              label="Reporter Settings"
              value="reporter-settings"
              data-testid="reporter-settings-tab"
            />
            ) (
            <StyledTab
              icon={<WorklistSettingsIcon />}
              label="Worklist Settings"
              value="worklist-settings"
              data-testid="worklist-settings-tab"
            />
            )
            <StyledTab
              icon={<SecuritySettingsIcon />}
              label="Security Settings"
              value="security-settings"
              data-testid="security-settings-tab"
            />
          </StyledTabs>
        </div>
        <StyledTabPanel value="personal-information">
          <PersonalInformationTab error={error} onClose={onClose} setError={setError} />
        </StyledTabPanel>
        <StyledTabPanel value="viewer-settings">
          <ViewerSettingsTab onClose={onClose} setError={setError} />
        </StyledTabPanel>
        <StyledTabPanel value="security-settings">
          <SecuritySettingsTab onClose={onClose} />
        </StyledTabPanel>
        <StyledTabPanel value="reporter-settings">
          <ReporterSettingsTab onClose={onClose} setError={setError} />
        </StyledTabPanel>
        <StyledTabPanel value="worklist-settings">
          <WorklistSettingsTab onClose={onClose} setError={setError} />
        </StyledTabPanel>
      </Stack>
    </TabContext>
  );
}

export default Account;
